import { useMemo } from 'react';

import {
  Autocomplete,
  Avatar,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Control, Controller } from 'react-hook-form';

import useAuthUser from '../../hooks/useAuthUser';
import useGql from '../../hooks/useGql';
import { stringAvatar } from '../../utils/avatars';

export interface UserOption {
  id: string;
  label: string; // e.g. name
  email: string; // e.g. name
}
export interface UserSelectProps {
  name: string;
  control: Control<any, any>;
  excludedUserIds?: string[];
}

const UserSelect = ({ name, control, excludedUserIds }: UserSelectProps) => {
  const { user, isLoading: isUserLoading } = useAuthUser();
  const { gqlSdk } = useGql();
  const { data } = useQuery({
    queryKey: ['users'],
    queryFn: () =>
      gqlSdk?.getUsers({
        where: {
          id: {
            not: {
              equals: user?.sub,
            },
          },
          isActive: {
            equals: true,
          },
        },
      }),
    enabled: !!gqlSdk && !isUserLoading,
  });

  const userOptions: UserOption[] = useMemo(() => {
    return (
      data?.users
        .filter(user => !excludedUserIds?.includes(user.id)) // filtering out excluded users
        .map(user => {
          return {
            id: user.id,
            label: user.name,
            email: user.email,
          };
        }) ?? []
    );
  }, [data, excludedUserIds]);

  return (
    <Controller
      render={({ field }) => (
        <Autocomplete
          // filterOptions={x => x}
          {...field}
          value={field.value || []}
          multiple
          options={userOptions}
          getOptionLabel={(option: UserOption) => (option ? option.label : '')}
          autoComplete
          filterSelectedOptions
          noOptionsText="No users found"
          // onInputChange={(event, newInputValue) => {
          //   setInputValue(newInputValue);
          // }}
          renderInput={params => (
            <TextField {...params} label="users" fullWidth />
          )}
          isOptionEqualToValue={(option: UserOption, value: UserOption) =>
            option.id === value.id
          }
          renderOption={(props, option: UserOption) => {
            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: 'flex', width: 44 }}>
                    <Avatar {...stringAvatar(option.label)} />
                  </Grid>
                  <Grid
                    item
                    sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
                  >
                    <Typography variant="body1" color="text.secondary">
                      {option.label}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
          onChange={(_, data) => field.onChange(data)}
          sx={{ width: 300 }}
        />
      )}
      name={name}
      control={control}
      defaultValue={[]}
    />
  );
};

export default UserSelect;
