import React from 'react';

import { TextField, Chip, Box } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

export interface EmailFormProps {
  name: string;
  control: Control<any, any>;
}

const EmailChipInput = ({ name, control }: EmailFormProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <TextField
            label="enter email addresses"
            onKeyDown={event => {
              if (event.key === ' ') {
                event.preventDefault();
                const target = event.target as HTMLInputElement;
                const newEmail = target.value.trim();
                if (newEmail) {
                  onChange([...value, newEmail]);
                  target.value = '';
                }
              }
            }}
            fullWidth
            margin="normal"
            variant="outlined"
            error={!!error}
            helperText={error ? error.message : null}
            InputProps={{
              startAdornment: (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {value.map((email: string, index: string) => (
                    <Chip
                      key={index}
                      label={email}
                      onDelete={() => {
                        onChange(value.filter((e: string) => e !== email));
                      }}
                      size="small"
                    />
                  ))}
                </Box>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default EmailChipInput;
