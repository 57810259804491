import { Button, Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';

import EmailChipInput from './EmailForm';
import { queryClient } from '../../api';
import useAuthUser from '../../hooks/useAuthUser';
import useGql from '../../hooks/useGql';
import UserSelect from '../selects/UserSelect';

interface InviteUsersFormProps {
  name: string; // Generic 'name' prop to represent either teamName or eventName
  id: string; // 'teamId' or 'eventId'
  type: 'team' | 'event'; // New prop to distinguish between team and event
  excludedUserIds?: string[];
  startDate?: string;
}

interface EmailForm {
  emails: [];
  name: string;
  inviteUrl: string;
}

interface User {
  id: string;
  name: string;
  email: string;
}

const EmailInviteForm = ({
  name,
  id,
  type,
  excludedUserIds,
  startDate,
}: InviteUsersFormProps) => {
  const { user, isLoading: isUserLoading } = useAuthUser();

  const { register, handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      users: [],
      emailAddresses: [],
    },
  });
  const { gqlSdk } = useGql();

  // Function to get all email users
  async function getAllEmailUsers(emails: string[]) {
    const emailUsersResponse = await gqlSdk?.getUsers({
      where: {
        email: {
          in: emails,
        },
      },
    });

    const emailUsers = emailUsersResponse?.users || [];
    return emailUsers;
  }

  const { mutateAsync: handleEmailInviteMutation, isPending: isPending } =
    useMutation({
      mutationFn: async (formData: EmailForm) => {
        const result = await gqlSdk?.emailInvite({
          emails: formData.emails,
          invitedBy: user?.name as string,
          invitedByID: user?.sub as string,
          name: formData.name,
          eventStartDate: startDate || '',
          inviteType: type,
          id: id,
        });

        const users = await getAllEmailUsers(formData.emails);

        return users as User[];
      },
      onSuccess: users => {
        queryClient.invalidateQueries({
          queryKey: ['getEventInvitedUsers', id],
        });
        queryClient.invalidateQueries({
          queryKey: ['getTournament', id],
        });
        queryClient.invalidateQueries({
          queryKey: ['getUserEventInvites', id],
        });
        queryClient.invalidateQueries({ queryKey: ['getTeam', id] });
      },
      onError: error => {
        let tempMessage = 'Unknown error occurred';
        if (error instanceof AxiosError) {
          tempMessage = error.response?.data.message ?? error.message;
        }
      },
    });

  const onSubmit = async (formData: any) => {
    const userEmails = formData.users.map(
      (user: { email: string }) => user.email
    );

    const emailAddresses = [...userEmails, ...formData.emailAddresses];

    // Handle email invites first
    if (emailAddresses && emailAddresses.length > 0) {
      await handleEmailInviteMutation({
        emails: emailAddresses as [],
        name,
        inviteUrl: `${process.env.NEXT_PUBLIC_FULL_PATH}/${
          type === 'team' ? 'teams' : 'events'
        }/${id}`,
      });
    }

    // Reset form and set success states
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack padding={2} spacing={2}>
        <UserSelect
          control={control}
          name="users"
          excludedUserIds={excludedUserIds}
        />
        <EmailChipInput control={control} name="emailAddresses" />
        <Button type="submit" variant="contained">
          {type === 'team' ? 'invite teammates' : 'invite participants'}
        </Button>
      </Stack>
    </form>
  );
};

/*
Exporting wrapper components for TeamEmailInviteForm and EventEmailInviteForm
rather than exporting the EmailInviteForm directly to avoid prop confusion
*/

interface EventEmailInviteFormProps {
  eventName: string; // Generic 'name' prop to represent either teamName or eventName
  eventId: string; // 'teamId' or 'eventId'
  excludedUserIds?: string[];
  eventStartDate?: string;
}
export const EventEmailInviteForm = ({
  eventName,
  eventId,
  excludedUserIds,
  eventStartDate,
}: EventEmailInviteFormProps) => {
  return (
    <EmailInviteForm
      name={eventName}
      id={eventId}
      type="event"
      excludedUserIds={excludedUserIds}
      startDate={eventStartDate}
    />
  );
};

interface TeamEmailInviteFormProps {
  teamName: string;
  teamId: string;
  excludedUserIds?: string[];
}
export const TeamEmailInviteForm = ({
  teamName,
  teamId,
  excludedUserIds,
}: TeamEmailInviteFormProps) => {
  return (
    <EmailInviteForm
      name={teamName}
      id={teamId}
      type="team"
      excludedUserIds={excludedUserIds}
    />
  );
};
