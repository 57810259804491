import React, { useState } from 'react';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  IconButton,
  Checkbox,
  Button,
} from '@mui/material';
import { useRouter } from 'next/router';

import { stringAvatar } from '../../utils/avatars';

type User = {
  id: string;
  name: string;
};

interface UsersListProps {
  users: User[];
  onRemoveUser?: (userId: string, userName: string) => void;
  cannotRemoveUserIds?: string[];
  allowRemoveUser?: boolean;
  checkedUsers?: string[];
  setCheckedUsers?: (checked: string[]) => void;
  highlightUserIds?: string[]; // New prop to indicate next-up players
  statusIcons?: { [userId: string]: JSX.Element };
  onSelectUser?: (userId: string) => void; // New prop for selecting users
  handleSetUsers?: (index1: number, index2: number) => Promise<void>; // Mutation function for swapping users
  offset?: number;
}

export default function UsersList({
  users,
  onRemoveUser,
  cannotRemoveUserIds,
  allowRemoveUser,
  checkedUsers,
  setCheckedUsers,
  highlightUserIds,
  statusIcons,
  onSelectUser,
  handleSetUsers,
  offset,
}: UsersListProps) {
  const router = useRouter();
  const [selectedForSwap, setSelectedForSwap] = useState<User[]>([]);

  const handleUserClickForSwap = (user: User) => {
    if (selectedForSwap.find(u => u.id === user.id)) {
      setSelectedForSwap(selectedForSwap.filter(u => u.id !== user.id));
    } else if (selectedForSwap.length < 2) {
      setSelectedForSwap([...selectedForSwap, user]);
    }
  };

  const handleSwapUsers = () => {
    if (
      selectedForSwap.length === 2 &&
      handleSetUsers &&
      typeof offset === 'number'
    ) {
      const [user1, user2] = selectedForSwap;
      const index1 = users.findIndex(u => u.id === user1.id);
      const index2 = users.findIndex(u => u.id === user2.id);
      handleSetUsers(index1 + offset, index2 + offset);
      setSelectedForSwap([]); // Clear selection after swap
    }
  };

  let handleCheckboxToggle: (userId: string) => void;
  if (checkedUsers && setCheckedUsers) {
    handleCheckboxToggle = (userId: string) => {
      const currentIndex = checkedUsers.indexOf(userId);
      const newChecked = [...checkedUsers];

      if (currentIndex === -1) {
        newChecked.push(userId);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setCheckedUsers(newChecked); // Update the state in the parent component
    };
  }

  return (
    <>
      <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {users.map((user, i) => {
          const statusIcon = statusIcons?.[user.id]; // Get the icon from statusIcons
          const isHighlighted = highlightUserIds?.includes(user.id);
          const isSelectedForSwap = selectedForSwap.find(u => u.id === user.id);

          return (
            <React.Fragment key={user.id}>
              <ListItem
                disablePadding
                sx={{
                  bgcolor: isSelectedForSwap
                    ? 'secondary.light'
                    : isHighlighted
                    ? 'primary.light'
                    : 'background.paper',
                  border: isSelectedForSwap ? '2px solid #1976d2' : 'none',
                }}
                secondaryAction={
                  <>
                    {statusIcon}
                    {onRemoveUser &&
                      allowRemoveUser &&
                      (!cannotRemoveUserIds ||
                        !cannotRemoveUserIds.includes(user.id)) && (
                        <IconButton
                          aria-label="remove-user"
                          onClick={() => onRemoveUser(user.id, user.name)}
                        >
                          <RemoveCircleOutlineIcon color="error" />
                        </IconButton>
                      )}
                    {handleCheckboxToggle && checkedUsers && (
                      <Checkbox
                        edge="end"
                        onChange={() => handleCheckboxToggle(user.id)}
                        checked={checkedUsers.includes(user.id)}
                        inputProps={{
                          'aria-labelledby': `checkbox-list-label-${user.id}`,
                        }}
                      />
                    )}
                  </>
                }
              >
                <ListItemButton
                  // onClick={() => handleUserClickForSwap(user)}
                  onClick={() => {
                    if (onSelectUser) {
                      onSelectUser(user.id);
                    } else if (handleSetUsers) {
                      handleUserClickForSwap(user);
                    } else {
                      router.push('/stats/' + user.id);
                    }
                  }}
                >
                  <ListItemAvatar>
                    <Avatar {...stringAvatar(user.name)} />
                  </ListItemAvatar>
                  <ListItemText primary={user.name} />
                </ListItemButton>
              </ListItem>
              {i < users.length - 1 && (
                <Divider variant="inset" component="li" />
              )}
            </React.Fragment>
          );
        })}
      </List>
      {selectedForSwap.length === 2 && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleSwapUsers}
          sx={{ mt: 2 }}
          data-testid="swap-select-button"
        >
          Swap Selected Users
        </Button>
      )}
    </>
  );
}
